import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const Mendix = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Mendix"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">TEQT is a strategic partner of Mendix, a leading low-code application development platform. With a strong focus on digital transformation and modernization, TEQT helps businesses leverage the power of Mendix to accelerate their application development journey.</p>
                        <p className="mt-3">
                        As a top-tier Mendix partner, TEQT brings over 16+ years of experience in delivering innovative solutions to its clients. Our partnership with Mendix enables us to offer a wide range of services, including product blueprinting/discovery, product engineering, strategic consulting, cloud migration, and data analytics.</p>
                        <p className="mt-3">TEQT's expertise in Mendix allows us to assist organizations in building custom applications rapidly and efficiently:</p>

                        <ul className="circle-list">
                            <li className="mt-2">By leveraging the Mendix low-code platform, we empower businesses to streamline their development processes, reduce time-to-market, and enhance agility. Our highly skilled professionals possess extensive experience in Mendix and employ customized methodologies and frameworks to deliver value-added solutions.</li>
                            <li className="mt-2">TEQT has developed proprietary tools and accelerators that complement the Mendix platform. These tools not only expedite development but also ensure delivery assurance, reducing both time and cost for our clients. We have established a dedicated and focused Mendix Centre of Excellence (COE), which serves as a hub for best practices, use cases, and continuous improvement.</li>
                            <li className="mt-2">Through our partnership with Mendix, TEQT aims to enable organizations to unlock their full potential by embracing low-code development. Together, we strive to drive digital innovation, improve operational efficiencies, and create compelling user experiences across industries.</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query MendixPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

Mendix.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Mendix;
